<template>
  <div>
    <div class="space-between align-center">
      <div class="align-center flex-1">
        <div class="filter-icon">
          <img src="/img/filter.svg" alt="" />
        </div>
        <div class="search-wrappper">
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              placeholder="Search here..."
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <my-button
        type="orange"
        @click="addModal = true"
      >
        <fa-icon icon="edit" class="my-icon" />&nbsp; BUAT PAKET SOAL BARU
      </my-button>
    </div>
    <br />
    <br />

    <label>Filter Jenjang</label><br />
      <b-input-group size="sm">
        <b-form-select
          v-model="tryout_level_id"
          :options="levelOptionList"
          @change="changeLevel"
          class="my-select"
        ></b-form-select>
      </b-input-group>
    <br />
    <label>Filter Mata Pelajaran</label><br />
      <b-input-group size="sm">
        <b-form-select
          v-model="tryout_subject_id"
          :options="subjectOptionList"
          @change="changeSubject"
          class="my-select"
        ></b-form-select>
      </b-input-group>
    <br /><br />

    <!-- Main table element -->
    <b-table
      show-empty
      stacked="md"
      :busy="isBusy"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(actions)="row">
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
          @click="handleEdit(row.item.id)"
        >
          <fa-icon icon="pencil-alt" class="my-icon" />&nbsp;Edit
        </b-button>
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-danger"
          @click="handleDelete(row.item)"
        >
          <fa-icon icon="minus-circle" class="my-icon" />&nbsp;Delete
        </b-button>
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-modal
      v-model="addModal"
      id="addModal"
      hide-header
      hide-footer
      centered
      size="lg"
    >
      <modal-cross @close="addModal = false"></modal-cross>
      <div class="p-4">
        <h3 class="txt-center color2">Buat Paket Soal Baru</h3>
        <br />
        <label>Pilih Jenjang</label>
        <br />
        <b-form-select
          v-model="inputData.tryout_level_id"
          :options="levelOption"
          required
        ></b-form-select>
        <br />
        <br />
        <label>Pilih Mata Pelajaran</label>
        <br />
        <b-form-select
          v-model="inputData.tryout_subject_id"
          :options="subjectOption"
          required
        ></b-form-select>
        <br />
        <br />
        <br />
        <label>Judul Paket Soal</label>
        <br />
        <b-form-input
          placeholder="Nama .. "
          v-model="inputData.name"
        ></b-form-input>
        <br /><br />
        <label>Durasi Soal (menit)</label>
        <b-form-input
          placeholder="Time"
          v-model="inputData.time_limit"
          type="number"
        ></b-form-input>
        <br /><br />
        <div class="justify-end">
          <my-button
            type="blue"
            disabled
            v-show="showLoading"
          >
            <b-spinner small></b-spinner>
            <span class="sr-only">Loading...</span>
          </my-button>
          <my-button
            type="blue"
            v-show="!showLoading"
            @click="addPackage"
          >
            SIMPAN & PILIH SOAL
          </my-button>
        </div>
        <br />
      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      tryout_level_id: null,
      tryout_subject_id: null,
      levelOptionList: [],
      subjectOptionList: [],
      isBusy: false,
      items: [],
      fields: [
        {
          key: "no",
          label: "No"
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "tryout_subject",
          label: "Mapel",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "tryout_level",
          label: "Jenjang",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "question",
          label: "Soal",
          sortable: true
        },
        {
          key: "actions",
          label: "Action"
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      addModal: false,
      editModal: false,
      inputData: {
        name: "",
        time_limit: "",
        tryout_subject_id: null
      },
      showLoading: false,
      levelOption: [],
      subjectOption: []
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn"
    })
  },
  created() {
    if (!this.isLoggedIn) {
      this.$router.push("/login");
    }
  },

  mounted() {
    this.totalRows = this.items.length;
    this.levelList();
    this.subjectList();
    if(this.$route.query.tryout_level) {
      this.tryout_level_id = this.$route.query.tryout_level;
    }
    if(this.$route.query.tryout_subject) {
      this.tryout_subject_id = this.$route.query.tryout_subject;
    }
    this.tryoutPackageList();
  },

  watch: {
    $route: function(newVal) {
      this.tryout_level_id = this.$route.query.tryout_level ? this.$route.query.tryout_level : null;
      this.tryout_subject_id = this.$route.query.tryout_subject ? this.$route.query.tryout_subject : null;
      this.subjectList();
      this.tryoutPackageList();
    },
    "inputData.tryout_level_id": function(newVal) {
      this.getTryoutSubjectList({params: {tryout_level: newVal}})
      .then(response => {
        var res = response;
        this.subjectOption = [];
        for (var i = 0; i < res.length; i++) {
          var datasubject = {
            value: res[i].id,
            text: res[i].name
          };
          this.subjectOption.push(datasubject);
        }
      })
      .catch(error => {
        this.showMessageBox(error, "Failed", "success");
      });
    }
  },

  methods: {
    ...mapActions({
      getTryoutLevelList: types.GET_TRYOUT_LEVEL_LIST,
      getTryoutSubjectList: types.GET_TRYOUT_SUBJECT_LIST,
      getTryoutPackageList: types.GET_TRYOUT_PACKAGE_LIST,
      setNewTryoutPackage: types.SET_NEW_TRYOUT_PACKAGE,
      deleteTryoutPackage: types.DELETE_TRYOUT_PACKAGE
    }),
    changeSubject() {
      if(this.tryout_subject_id) {
        this.$router.push("/tryout-package/list?tryout_level="+this.tryout_level_id+"&tryout_subject="+this.tryout_subject_id);
      } else {
        this.$router.push("/tryout-package/list?tryout_level="+this.tryout_level_id);
      }
    },
    changeLevel() {
      if(this.tryout_level_id) {
        this.$router.push("/tryout-package/list?tryout_level="+this.tryout_level_id);
      } else {
        this.$router.push("/tryout-package/list");
      }
    },
    levelList() {
      this.getTryoutLevelList()
        .then(response => {
          var res = response;
          this.levelOption = [];
          this.levelOptionList = [{value: null, text: "All"}];
          for (var i = 0; i < res.length; i++) {
            var dataLevel = {
              value: res[i].id,
              text: res[i].name
            };
            this.levelOption.push(dataLevel);
            this.levelOptionList.push(dataLevel);
          }
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    subjectList() {
      var tryout_level_id = this.$route.query.tryout_level ? this.$route.query.tryout_level : null;
      if(tryout_level_id) {
        this.getTryoutSubjectList({params: {tryout_level: tryout_level_id}})
          .then(response => {
            var res = response;
            this.subjectOptionList = [{value: null, text: "All"}];
            for (var i = 0; i < res.length; i++) {
              var datasubject = {
                value: res[i].id,
                text: res[i].name
              };
              this.subjectOptionList.push(datasubject);
            }
          })
          .catch(error => {
            this.showMessageBox(error, "Failed", "success");
          });
      } else {
        this.subjectOptionList = [{value: null, text: "All"}];
      }
    },
    tryoutPackageList(tryout_level_id = null, tryout_subject_id=null) {
      tryout_level_id = this.$route.query.tryout_level ? this.$route.query.tryout_level : null;
      tryout_subject_id = this.$route.query.tryout_subject ? this.$route.query.tryout_subject : null;
      this.isBusy = true;
      this.getTryoutPackageList({params: {tryout_level: tryout_level_id, tryout_subject: tryout_subject_id}})
        .then(response => {
          var res = response;
          this.items = [];
          for (var i = 0; i < res.length; i++) {
            var dataItem = {
              no: i + 1,
              id: res[i].id,
              name: res[i].name,
              tryout_level: res[i].tryout_level,
              tryout_level_id: res[i].tryout_level_id,
              tryout_subject: res[i].tryout_subject,
              tryout_subject_id: res[i].tryout_subject_id,
              question: res[i].easy+' easy, '+res[i].medium+' medium, '+res[i].hard+' hard'
            };
            this.items.push(dataItem);
          }
          this.totalRows = res.length;
          this.isBusy = false;
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleEdit(no) {
      this.$router.push("/tryout-package/edit/"+no);
    },
    handleDelete(index) {
      this.$bvModal.msgBoxConfirm('Delete this data?', {
        title: 'Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if(value) {
            this.deleteTryoutPackage({id:index.id})
              .then(response => {
                this.tryoutPackageList();
                this.showMessageBox("Deleted", "Success", "success");
              })
              .catch(error => {
                this.showMessageBox(error, "Failed", "success");
              });
          }
        })
        .catch(err => {
          this.showMessageBox(err, "Failed", "success");
        });
    },
    closeModal() {
      this.$bvModal.hide("modal-confirm");
    },
    addPackage() {
      this.showLoading = true;
      this.setNewTryoutPackage(this.inputData)
        .then(response => {
          // eslint-disable-next-line
          var res = response;
          this.addModal = false;
          this.showLoading = false;
          this.$router.push("/tryout-package/edit/"+res[0]);
        })
        .catch(error => {
          this.showLoading = false;
          this.showMessageBox(error, "Failed", "success");
        });
    },
    editMajor() {
      this.showLoading = true;
      this.updateMajor(this.editData)
        .then(response => {
          // eslint-disable-next-line
          var res = response;
          this.editModal = false;
          this.showLoading = false;
          this.tryoutPackageList();
        })
        .catch(error => {
          this.showLoading = false;
          this.showMessageBox(error, "Failed", "success");
        });
    },
    showMessageBox(message, title, variant) {
      this.$bvModal
        .msgBoxOk(message, {
          title: title,
          size: "sm",
          buttonSize: "sm",
          okVariant: variant,
          centered: true
        })
        .then(value => {
          this.temp = value;
        })
        .catch(err => {
          this.temp = err;
        });
    }
  }
};
</script>

<style>
.search-wrapper {
  width: 100%;
  max-width: 222px;
}

.filter-icon {
  margin-right: 11px;
  background-color: #e9e9e9;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
  padding-top: 7px;
}

.action-button:hover {
  background-color: #f08045;
  border: 1px solid #f08045;
}
</style>